.file-input-btn {
  border-radius: 0;
  border: none;
  padding: 0.5em 1em;
}

.btn-default {
  background-color: #028f56;
  color: #fff;
}

.btn-default:hover {
  background-color: #01a664;
  color: #fff;
}

.file-input {
  cursor: pointer;
}

.file-input > input[type='file'] {
  display: none;
}
