.dropzone-container {
  height: 100%;
  width: 100%;
}

.filepond--panel-root {
  border: solid 3px rgb(165, 165, 165);
}

.filepond--root {
  max-height: 15em;
}

.filepond--file {
  background: rgb(3, 118, 72);
  background: linear-gradient(
    343deg,
    rgba(3, 118, 72, 0.9416141456582633) 74%,
    rgba(0, 129, 41, 1) 100%
  );
}
