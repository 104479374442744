.base-badge {
  background-color: #959595;
  color: rgb(131, 131, 131);
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  display: inline-block;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.3s;
}

.base-badge.success {
  background-color: #4caf50;
  color: white;
}

.base-badge.danger {
  background-color: #f44336;
  color: white;
}

.base-badge.warning {
  background-color: #ff9800;
  color: white;
}

.base-badge.info {
  background-color: #2196f3;
  color: white;
}

.base-badg.sm {
  padding: 3px 5px;
  font-size: 10px;
}

.base-badge.lg {
  padding: 7px 15px;
  font-size: 14px;
}
