.profilepic-container {
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.profilepic {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  overflow: hidden;
  background-color: #111;
  border: 0.2rem solid #028f56;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.3);
}

.profilepic-container.small {
  width: 75px;
  height: 75px;
}

.profilepic-container.medium {
  width: 100px;
  height: 100px;
}

.profilepic-container.large {
  width: 125px;
  height: 125px;
}

.profilepic:hover.editable {
  cursor: pointer;
}

.profilepic:hover .profilepic-content {
  opacity: 1;
}

.profilepic:hover.editable .profilepic-image {
  opacity: 0.5;
}

.profilepic-image {
  object-fit: cover;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  width: 150px;
}

.profilepic-image.small {
  width: 80px;
}

.profilepic-image.medium {
  width: 105px;
}

.profilepic-image.large {
  width: 130px;
}

.profilepic-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.profilepic-icon {
  color: white;
  padding-bottom: 8px;
}

.profilepic-text {
  text-transform: uppercase;
  font-size: 12px;
  width: 50%;
  text-align: center;
}

/* < md */
@media (max-width: 768px) {
  .profilepic-container.small {
    width: 25px;
    height: 25px;
  }

  .profilepic-container.medium {
    width: 50px;
    height: 50px;
  }

  .profilepic-container.large {
    display: flex;
    align-items: flex-start;
    width: 75px;
    height: 75px;
  }

  .profilepic-image.small {
    width: 30px;
  }

  .profilepic-image.medium {
    width: 55px;
  }

  .profilepic-image.large {
    width: 80px;
  }
}
