.sidebar-wrapper {
  position: relative;
  display: flex;
  height: calc(100% - 85px);
}

.dialog-container {
  height: 100%;
  width: 100%;
}

.dialog-container-body {
  position: relative;
  display: flex;
  height: calc(100% - 60px);
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1em;
  padding-left: 1.5em;
  padding-right: 1.5em;

  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.dialog-container-footer {
  display: flex;
  height: 60px;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  border-top: 1px solid #000000;
  width: 100%;
  font-size: xx-large;
  transition: all 0.3s ease;
  background-color: #e6e6e6;
}

.dialog-container-footer:hover {
  cursor: pointer;
  background-color: #cdcdcd;
}

.no-farms-text {
  font-size: 1em;
  font-weight: 600;
  color: #000000;
  margin-top: 0.5em;
}

.bottom-sheet-content {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  height: fit-content;
  width: 100%;
  gap: 0px;
  padding: 10px 15px 10px 15px;
}
