.icon-button {
  position: relative;
  left: 0;
  margin-right: 1.5em;
  font-size: larger;
  transition: all 0.3s ease;
  border: none;
  background-color: transparent;
}

.icon-button:hover {
  cursor: pointer;
  transform: scale(1.1);
}
