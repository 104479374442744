.user-name {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.custom-phone-icon {
  -webkit-text-stroke: 1px white;
  color: #028f56;
}

.custom-envelope-icon {
  -webkit-text-stroke: 1px white;
  color: #028f56;
}
