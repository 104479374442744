.form-sidebar-container {
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 35em;
  border-right: 2px solid #000000;
  background-color: #e6e6e6;
}

.sidebar-body {
  display: flex;
  height: calc(100% - 120px);
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1em;
  padding-left: 1.5em;
  padding-right: 1.5em;

  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.bottom-sheet-content {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  height: fit-content;
  width: 100%;
  gap: 0px;
  padding: 10px 15px 10px 15px;
}

.sidebar-header {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #000000;
  height: 85px;
}

.sidebar-header .title {
  font-size: 1.5em;
  font-weight: medium;
}

.sidebar-header .icon-button {
  position: absolute;
  left: 0;
  margin-left: 1.5em;
  font-size: large;
  transition: all 0.3s ease;
}

.sidebar-header .icon-button:hover {
  cursor: pointer;
  transform: scale(1.1);
}
