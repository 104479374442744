.table-flush {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.thead-light td {
  background-color: #e8e8e8;
  color: #000000ab;
  font-weight: bold;
  font-size: 14px;
}

.thead-dark td {
  background-color: #000000ab;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
}

thead tr td {
  background-color: #e8e8e8;
  color: #000000ab;
  font-weight: bold;
  font-size: 14px;
  width: fit-content;
  text-align: center;
}

tbody tr td {
  background-color: #fff;
  border-bottom: #00000017 1px solid;
  padding: 0;
  border-left: #00000017 1px solid;
  vertical-align: middle;
}

tbody.text-begin tr td {
  text-align: left;
}

tbody.text-center tr td {
  text-align: center;
}

tbody.text-end tr td {
  text-align: right;
}

tbody {
  display: block;
}

thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
