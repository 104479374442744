.farm-edit-sidebar {
  position: relative;
  height: 100%;
  width: 60vw;
  max-width: 30em;
  border-right: 2px solid #000000;
  background-color: #e6e6e6;
}

.farm-edit-map-container {
  position: relative;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: calc(100% - min(60vw, 30em));
  scrollbar-width: none;
}

@media (max-width: 991px) {
  .farm-edit-map-container {
    position: relative;
    align-items: center;
    height: calc(100% - 40px);
    max-width: 100%;
    width: 100%;
    scrollbar-width: none;
  }
}
