.pending-status {
  color: #3480f2;
  font-weight: bold;
}

.processed-status {
  color: #439a42;
  font-weight: bold;
}

.canceled-status {
  color: #f25434;
  font-weight: bold;
}
