.aufarm-logo-login {
  width: calc(50px + 15vw);
  max-width: 225px;
  min-width: 120px;
  height: auto;
}

.custom-card {
  width: 80%;
  height: 90%;
  max-width: 45em;
  background-color: #dcdcdc;
  border-radius: 10px;
  border: none;
}

.custom-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  font-weight: bolder;
}

.custom.container-fluid {
  display: flex;
  justify-content: center;
  background-color: transparent;
  height: 100%;
  padding: 0;
}

.badge-error {
  opacity: 0.5;
  background-color: #ff0000;
  color: #fff;
  font-weight: bolder;
  font-size: 0.8em;
  padding: 0.2em 0.5em;
  border-radius: 5px;
}
