.teste-map {
  position: relative;
  align-items: center;
  height: calc(100% - 40px);
  width: 100%;
  scrollbar-width: none;
}

.teste-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  height: fit-content;
  width: 100%;
  padding: 10px;
  gap: 10px;
}

.teste-item {
  background-color: white;
  box-shadow: 3px 5px 0.5em 1px #00000016;
  border-radius: 10px;
  width: 100%;
  height: 100px;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
