.request-form-sidebar {
  position: relative;
  height: 100%;
  width: 60vw;
  max-width: 30em;
  border-right: 2px solid #000000;
  background-color: #e6e6e6;
}

.request-form-map-container {
  position: relative;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: calc(100% - min(60vw, 30em));
  scrollbar-width: none;
}

/* < lg */
@media (max-width: 991px) {
  .request-form-map-container {
    position: relative;
    align-items: center;
    /* 100% - header - bottom-sheet */
    height: calc(100% - 35px - 40px);
    max-width: 100%;
    width: 100%;
    scrollbar-width: none;
  }

  .request-form-map-container__header {
    max-height: 35px !important;
    padding: 5px 10px;
    font-weight: bold;
  }

  .request-form-map-container__header .icon-button {
    display: flex;
    align-items: center;
    margin-right: 0;
    font-size: 15px;
    transition: all 0.3s ease;
  }
}
