.navbar {
  position: relative;
  height: 3em;
  width: 100%;
  z-index: 999;
  background-color: #028f56;
  display: 'flex';
  flex-direction: 'row';
  padding: 0.4em 1.2em;
  box-shadow:
    0 4px 6px 1px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.1);
}

.logo {
  position: relative;
  height: 30px;
  width: auto;
}
