.input-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 120px;
}

.MuiFormControl-root .MuiInputBase-root .MuiInput-input option {
  background-color: transparent;
}
