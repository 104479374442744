.interactive-map {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.marker-label {
  font-size: 20px;
  font-weight: 700;
  background-color: transparent;
  color: #fff;
  border: 0;
  box-shadow: none;
  margin-left: -15px;
  margin-top: -30px;
}

.leaflet-container {
  z-index: 0;
}
