.container-fluid.request-item {
  padding: 0.8em;
  height: auto;
  background-color: white;
  box-shadow: 3px 5px 0.5em 1px #00000016;
  transition: all 0.3s ease;
  border-radius: 5px;
  animation: fadeIn 0.4s ease;
}

.container-fluid.request-item:hover {
  cursor: pointer;
  transform: scale(1.02);
}

.item-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100% inherit;
  border-right: 1px solid #0000005b;
  font-size: 40px;
}

.pending-status {
  color: #3480f2;
  font-weight: bold;
}

.processed-status {
  color: #5ccd5a;
  font-weight: bold;
}

.canceled-status {
  color: #f25434;
  font-weight: bold;
}
