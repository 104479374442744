.area-list-sidebar {
  position: relative;
  height: 100%;
  width: 60vw;
  max-width: 30em;
  border-right: 2px solid #000000;
  background-color: #e6e6e6;
}

.area-list-map-container {
  position: relative;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: calc(100% - min(60vw, 30em));
  scrollbar-width: none;
}

.sidebar-header {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #000000;
  height: 85px;
}

.sidebar-header .title {
  font-size: 1.5em;
  font-weight: medium;
}

.sidebar-content {
  display: flex;
  height: calc(100% - 135px);
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1em;
  padding-left: 1.5em;
  padding-right: 1.5em;

  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.sidebar-footer {
  display: flex;
  height: 60px;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  border-top: 1px solid #000000;
  width: 100%;
  font-size: xx-large;
  transition: all 0.3s ease;
  background-color: #e6e6e6;
}

.sidebar-footer:hover {
  cursor: pointer;
  background-color: #cdcdcd;
}

.sidebar-header .icon-button {
  position: absolute;
  left: 0;
  margin-left: 1.5em;
  font-size: large;
  transition: all 0.3s ease;
}

.sidebar-header .icon-button:hover {
  cursor: pointer;
  transform: scale(1.1);
}

/* < lg */
@media (max-width: 991px) {
  .area-list-map-container {
    position: relative;
    align-items: center;
    /* 100% - header - bottom-sheet */
    height: calc(100% - 35px - 40px);
    max-width: 100%;
    width: 100%;
    scrollbar-width: none;

    .area-list-map-container__header {
      max-height: 35px !important;
      padding: 5px 10px;
      font-weight: bold;
    }

    .area-list-map-container__header .icon-button {
      display: flex;
      align-items: center;
      margin-right: 0;
      font-size: 15px;
      transition: all 0.3s ease;
    }
  }
}
