.counter-input {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100% inherit;
  margin: 0;
  padding: 0;
}

.counter-minus-btn,
.counter-plus-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);
}

.counter-minus-btn:hover,
.counter-plus-btn:hover {
  background-color: #dedede;
}

.counter-field {
  width: 50px;
  height: 30px;
  border: 1px solid #000;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-left: 5px;
  margin-right: 5px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3);
}
