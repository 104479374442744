body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.leaflet-container {
  height: 100%;
  width: 100%;
}

.MuiBox-root.css-19kzrtu {
  padding: 0;
}

.css-68o8xu,
.css-e784if-MuiTypography-root {
  margin: 0;
  font-family: inherit !important;
  font-weight: inherit !important;
  font-size: inherit !important;
  line-height: inherit !important;
  letter-spacing: inherit !important;
}

.aufarm-logo {
  font-family: 'Julius Sans One';
  height: fit-content;
  width: fit-content;
  color: #000;
  margin-bottom: '0px';
  text-decoration: none;
}

.default-green {
  color: #028f56;
}

.link-hover {
  text-decoration: none;
  color: #333;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: #007bff;
  }
}

.link-ancor {
  text-decoration: none;
  color: #007bff;
}

.text-danger-light {
  color: #f77e8e;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
