.base-button {
  border-radius: 0;
  border: none;
  padding: 0.5em 1em;
  background-color: #028f56;
  color: white;
  transition: 0.3s ease;
}

.base-button:disabled {
  background-color: #ccc;
  color: #666;
}

.base-button:not(:disabled):hover {
  background-color: #01a664;
}

.base-button.full-width {
  width: 100%;
}

.base-button.xs {
  padding: 0.15em 0.4em;
  font-size: 0.9em;
}

.base-button.sm {
  padding: 0.25em 0.5em;
}

.base-button.lg {
  padding: 0.75em 2.5em;
}
