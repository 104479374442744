.react-modal-sheet-content {
  background-color: #f5f7f9;
}

.bottom-sheet-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 40px;
  cursor: grab;
  padding-left: 10px;
  padding-right: 15px;
}

.bottom-sheet-header:active {
  cursor: grabbing;
}

.bottom-sheet-header-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: #000;
  margin-right: auto;
}

.draggable-icon {
  position: absolute;
  margin: auto;
  font-size: 2.5em;
}
