.floating-dialog-container {
  position: absolute;
  width: fit-content;
  height: fit-content;
  max-width: 90vw !important;
  z-index: 1000;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  user-select: none;
}

.floating-dialog-container * {
  user-select: text;
}

.floating-dialog-container.hidden {
  display: none;
}

.floating-dialog {
  display: flex;
  flex-direction: column;
  background-color: #028f57da;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: relative;
  animation: fadeIn 0.3s ease;
  transition: all 0.3s;
}

.floating-dialog-body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: -0.8em;
}

.floating-dialog.sm {
  width: 400px;
  min-height: 125px;
}

.floating-dialog.md {
  width: 600px;
  min-height: 125px;
}

.floating-dialog.lg {
  width: 800px;
  min-height: 125px;
}

.floating-dialog-container.position-center-top {
  left: 50%;
  transform: translateX(-50%);
  top: 0;
}

.floating-dialog-container.position-center-bottom {
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}

.floating-dialog-container.position-left-top {
  left: 0;
  top: 0;
}

.floating-dialog-container.position-left-bottom {
  left: 0;
  bottom: 0;
}

.floating-dialog-container.position-right-top {
  right: 0;
  top: 0;
}

.floating-dialog-container.position-right-bottom {
  right: 0;
  bottom: 0;
}

@media (max-width: 767px) {
  .floating-dialog-container {
    font-size: small;
    font-weight: 600;
  }

  .floating-dialog-container h5 {
    font-size: medium;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
