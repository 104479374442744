.navbar {
  display: flex;
  min-height: 3em;
  width: 100%;
  z-index: 99999;
  background-color: #028f56;
  padding: 0.4em;
  align-items: center;
  flex-shrink: 0;
  box-shadow:
    0 4px 6px 1px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-collapse {
  background-color: #028f56;
  width: fit-content !important;
  margin-left: auto;
  flex-grow: inherit;
  max-height: calc(100vh - 3em);
  overflow-y: auto;
}

.logo {
  position: relative;
  height: 30px;
  width: auto;
  margin-left: 0.6em;
}

.nav-link {
  font-size: 1em;
  height: 100%;
  width: fit-content;
  margin: 0 0.5em;
  margin-bottom: 3px;
}

.balance-summary-wrapper {
  margin-bottom: 3px;
  height: 100%;
}

/* X-small */
@media (max-width: 576px) {
  .logo {
    font-size: 1.1em !important;
  }
}
