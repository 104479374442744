.header-tab {
  position: relative;
  height: 3em;
  width: 100%;
  z-index: 999;
  background-color: #028f56;
  display: flex;
  flex-direction: row;
}

.page-content {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  padding: 1em;
  background-color: transparent;
}
