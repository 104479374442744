.info-pin-container {
  position: relative;
  width: fit-content;
  height: fit-content;
}

.info-pin {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: #c5c5c5;
  border-radius: 50%;
  font-size: 8px;
  transition: all 0.2s ease;
}

.info-pin.small {
  width: 15px;
  height: 15px;
  font-size: 6px;
}

.info-pin.medium {
  width: 20px;
  height: 20px;
  font-size: 8px;
}

.info-pin.large {
  width: 25px;
  height: 25px;
  font-size: 10px;
}

.info-pin:hover {
  background-color: #5fadff;
}
