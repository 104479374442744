.balance-summary {
  display: flex;
  flex-direction: row;

  width: 100%;
  min-width: 12em;
  height: 100%;
  min-height: 33px;
  max-height: 50px;
  border-radius: 10px;
  justify-content: space-between;
  background: #fff;
  text-align: center;
  align-items: center;
}

.balance-wrapper {
  color: #515151;
  margin-left: 1.3em;
  font-size: 14px;
  width: 100%;
  position: relative;
  padding-right: 10px;
  justify-content: space-between;
  align-items: center;
}

.balance-value {
  font-size: 15px;
  font-weight: 500;
}

.purchase-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: none;
  border-radius: 0px 10px 10px 0px;
  height: 100%;
  min-height: 31px;
  width: 50px;
  cursor: pointer;
  transition: all 0.3s ease;

  -webkit-box-shadow: -2.1px 0.29px 1.47px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -2.1px 0.29px 1.47px 0px rgba(0, 0, 0, 0.1);
  box-shadow: -2.1px 0.29px 1.47px 0px rgba(0, 0, 0, 0.1);
}

.plus-icon {
  font-size: 14px;
  font-weight: 200;
  transition: all 0.3s ease;
}

.purchase-button:hover .plus-icon {
  transform: scale(1.3);
}

/* X-small */
@media (max-width: 576px) {
  .balance-summary.xsm-responsive {
    min-width: 5em;
    height: 60%;
    min-height: 28px;
  }

  .balance-summary.xsm-responsive .balance-wrapper {
    margin-left: 1em;
    font-size: 9px;
    padding-right: 5px;
  }

  .balance-summary.xsm-responsive .balance-value {
    font-size: 9px;
  }

  .balance-summary.xsm-responsive .plus-icon {
    font-size: 12px;
    font-weight: 200;
  }

  .balance-summary.xsm-responsive .purchase-button {
    min-height: 28px;
    width: 35px;
  }
}
