.stats-card {
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  width: 100%;
  min-height: 100px;

  align-items: flex-start;
  justify-content: center;
}

.stats-card-header {
  display: flex;
  text-align: start;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
  margin-top: -7px;
}

.stats-card-title {
  max-width: calc(100% - 48px);
}

.stats-card-title.large-muted {
  font-size: 18pt;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.55);
}

.stats-card-title.small-bold {
  display: flex;
  font-size: 15pt;
  font-weight: 500;
  color: #000;
  align-items: center;
}

.stats-card-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #dedede;
  font-size: large;
  font-weight: 500;
}

.stats-card-subtitle {
  font-size: 20pt;
}

.stats-card-value {
  font-size: 22pt;
  color: #000;
  font-weight: 400;
}

.stats-card-description {
  font-size: 8pt;
  width: 45%;
}

.stats-card-body {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.stats-card-footer {
  display: flex;
  width: 100%;
}

.spacing-sm {
  gap: 15px;
}

.spacing-md {
  gap: 25px;
}

.spacing-lg {
  gap: 35px;
}

.spacing-xl {
  gap: 45px;
}
