.forgot-password-card {
  width: 80%;
  height: 90%;
  max-width: 60em;
  background-color: #dcdcdc;
  border-radius: 10px;
  border: none;
}

.forgot-password-card .aufarm-logo {
  width: 100%;
  max-width: 200px;
  min-width: 100px;
  height: auto;
}

.forgot-password-card .custom-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  font-weight: bolder;
}

.custom-counter {
  margin-right: 10px;
  font-size: 1.3em;
  animation: fadeIn 0.3s ease;
}

.scrollable-y {
  overflow-y: auto;
  height: 100%;
}

.cancel-button {
  font-size: large;
  transition: transform 0.3s ease;
}
.cancel-button:hover {
  transform: scale(1.1);
}
